import { round } from "@ugg/shared/utils/math";
import { RoleC, RoleN, RoleS, normalizeRole } from "@ugg/shared/utils/role-helpers";
import { RegionC, RegionN } from "@ugg/shared/utils/region-helpers";
import { RankC, RankN } from "@ugg/shared/utils/rank-helpers";

import { Matchups, MatchupChampionsData, MatchupChampion } from "@ugg/shared/interfaces/champions/matchups.interface";

function getKey(region: string, rank: string, role: string) {
  return `${region}_${rank}_${role}`;
}

export function getMatchups(data: ReturnType<typeof cleanMatchups>, params: { region: string; rank: string; role: string }) {
  const { region, rank, role } = params;

  if (!data) {
    return null;
  }

  return data[getKey(region, rank, normalizeRole(role, RoleS.ALL))];
}

export interface MatchupsInfo {
  counters: {
    champion_id: MatchupChampion[0];
    win_rate: number;
    pick_rate: number;
    tier: {
      pick_rate: number;
      win_rate: number;
    };
    matches: MatchupChampion[2];
    xp_adv_15: MatchupChampion[3];
    gold_adv_15: MatchupChampion[4];
    duo_gold_adv_15: MatchupChampion[5];
    cs_adv_15: MatchupChampion[6];
    duo_cs_adv_15: MatchupChampion[7];
    jungle_cs_adv_15: MatchupChampion[8];
    kill_adv_15: MatchupChampion[9];
    duo_kill_adv_15: MatchupChampion[10];
    duo_xp_adv_15: MatchupChampion[11];
    carry_percentage_15: MatchupChampion[12];
    duo_carry_percentage_15: MatchupChampion[13];
    team_gold_difference_15: MatchupChampion[14];
  }[];
  last_updated: MatchupChampionsData[1];
  matches: number;
}

export function cleanMatchups(data: Matchups) {
  const cleanData: Record<string, MatchupsInfo> = {};
  const regionKeys: RegionN[] = Object.keys(data || {}).map(Number);
  for (const regionId of regionKeys) {
    const regionData = data[regionId];
    const rankKeys: RankN[] = Object.keys(regionData || {}).map(Number);

    for (const rankId of rankKeys) {
      const rankData = regionData[rankId];
      const roleKeys: RoleN[] = Object.keys(rankData || {}).map(Number);

      for (const roleId of roleKeys) {
        let totalMatches = 0;
        const roleData = rankData[roleId];

        const counter: MatchupsInfo = {
          counters: roleData[0].map((x) => {
            const matches = x[2];
            totalMatches += matches;

            return {
              champion_id: x[0],
              win_rate: round((1 - x[1] / matches) * 100, 2),
              pick_rate: 0,
              tier: {
                pick_rate: 0,
                win_rate: 0,
              },
              matches: matches,
              xp_adv_15: round((x[3] / matches) * -1, 0),
              gold_adv_15: round((x[4] / matches) * -1, 0),
              duo_gold_adv_15: round((x[5] / matches) * -1, 0),
              cs_adv_15: round((x[6] / matches) * -1, 1),
              duo_cs_adv_15: round((x[7] / matches) * -1, 1),
              jungle_cs_adv_15: round((x[8] / matches) * -1, 1),
              kill_adv_15: round((x[9] / matches) * -1, 2),
              duo_kill_adv_15: round((x[10] / matches) * -1, 2),
              duo_xp_adv_15: round((x[11] / matches) * -1, 0),
              carry_percentage_15: round((x[12] / matches) * -1 * 10, 0),
              duo_carry_percentage_15: round((x[13] / matches) * -1 * 10, 0),
              team_gold_difference_15: round((x[14] / matches) * -1, 0),
            };
          }),
          last_updated: roleData[1],
          matches: totalMatches,
        };

        counter.counters.forEach((counter) => {
          const pick_rate = round((counter.matches / totalMatches) * 100, 2);
          counter["pick_rate"] = pick_rate;
          counter["tier"] = {
            pick_rate,
            win_rate: counter.win_rate,
          };
        });

        counter.counters.sort((a, b) => {
          if (a.pick_rate >= 0.5 && b.pick_rate >= 0.5) {
            if (a.win_rate > b.win_rate) return 1;
            else if (a.win_rate < b.win_rate) return -1;
            return 0;
          } else if (a.pick_rate >= 0.5 && b.pick_rate < 0.5) {
            return 1;
          } else if (a.pick_rate < 0.5 && b.pick_rate >= 0.5) {
            return -1;
          } else if (a.pick_rate < 0.5 && b.pick_rate < 0.5) {
            if (a.win_rate > b.win_rate) return 1;
            else if (a.win_rate < b.win_rate) return -1;
            return 0;
          }
          return 0;
        });

        const key = getKey(RegionC.convertToString(regionId), RankC.convertToString(rankId), RoleC.convertToString(roleId));

        cleanData[key] = counter;
      }
    }
  }

  return cleanData;
}
