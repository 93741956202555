import { useJSONFetcher } from "@outplayed/json-fetcher";
import { useUGGApiVersions } from "../ugg-api-versions";
import { usePrimaryRoles } from "./primary-roles";
import { useValidatedChampionProfileParams } from "./common";
import { getApiRoot } from "../../api-helpers";
import { cleanMatchups } from "../../../api/data-parser/champions/matchups";
import { CHAMPION_PAGES } from "../../../pages/champion-pages";

import { Matchups } from "../../../interfaces/champions/matchups.interface";

export function useMatchupsURL(championId: number, options: { queueType?: string; patch?: string }) {
  const { queueType, patch } = options;
  const { data: version } = useUGGApiVersions({ apiKey: "matchups", patch });
  const root = getApiRoot(patch);

  return [`${root}/matchups`, patch, queueType, championId, `${version}.json`].join("/");
}

// Counters and Matchups Page
export function useChampionMatchups(championId: number, options?: { ssr?: boolean; skip?: boolean }) {
  const { ssr = false, skip = false } = options || {};
  const { validatedParams } = useValidatedChampionProfileParams(championId, CHAMPION_PAGES.MATCHUPS, { keepDefaultParams: true });
  const { data: primaryRoles, loading: loadingPrimaryRoles } = usePrimaryRoles({ ssr });

  const championMatchupsURL = useMatchupsURL(championId, validatedParams);
  const fetchState = useJSONFetcher<Matchups, ReturnType<typeof cleanMatchups>>(championMatchupsURL, {
    ssr,
    skip: !primaryRoles || skip,
    onCompleted: (url, json) => cleanMatchups(json) || {},
  });

  return { ...fetchState, loading: loadingPrimaryRoles || fetchState.loading };
}
