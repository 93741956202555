import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import CountersListView from "./CountersListView";
import CounterTips from "./CounterTips";
import { getNotFoundImg } from "@outplayed/riot-assets";
import { useJSONFetcher } from "@outplayed/json-fetcher";
import { useChampionRankingStats } from "@ugg/shared/api/requests/champions/ranking-stats";
import { getRankings } from "@ugg/shared/api/data-parser/champions/ranking-stats";
import { getMatchups } from "@ugg/shared/api/data-parser/champions/matchups";
import { useChampionMatchups } from "@ugg/shared/api/requests/champions/matchups";
import { useValidatedChampionProfileParams } from "@ugg/shared/api/requests/champions/common";
import { useChampionProfileContext } from "@ugg/shared/components/Champions/ChampionProfileContext";
import { CHAMPION_PAGES } from "@ugg/shared/pages/champion-pages";

const CountersContainer = () => {
  const location = useLocation();
  const [listCount, setListCount] = useState(10);
  const { championId, championData, role } = useChampionProfileContext();

  const incrementListCount = (totalChampCount) => {
    setListCount(totalChampCount);
  };

  useEffect(() => {
    setListCount(10);
  }, [championId, location.search]);

  const { data: matchups, loading: fetchingMatchups, error: errorMatchups } = useChampionMatchups(championId, { ssr: true });
  const {
    data: rankingStats,
    loading: fetchingRankingStats,
    error: errorRankingStats,
  } = useChampionRankingStats(championId, { ssr: true });
  const envDir = process.env.RIOT_PATCH_ASSETS === "staging" ? "staging" : "prod";
  const {
    data: counterTips,
    loading: loadingJson,
    error: errorJson,
  } = useJSONFetcher(`https://static.bigbrain.gg/assets/lol/riot_patch_update/${envDir}/ugg/counter-tips.json`, { ssr: true });

  let { validatedParams } = useValidatedChampionProfileParams(championId, CHAMPION_PAGES.COUNTERS, { keepDefaultParams: true });
  validatedParams = { ...validatedParams, role };

  if (fetchingMatchups || fetchingRankingStats || loadingJson || errorJson) {
    return <div className="champion-profile-placeholder basic-placeholder shimmer-placeholder" />;
  }

  let filteredMatchups = getMatchups(matchups, validatedParams);
  const filteredRankingStats = getRankings(rankingStats, validatedParams);
  filteredMatchups = (filteredMatchups && filteredMatchups.counters) || [];
  filteredMatchups = filteredMatchups.filter((champion) => champion.pick_rate >= 0.5);

  const commonProps = {
    listCount,
    incrementListCount,
    championId,
    championName: championData.name,
    filters: validatedParams,
    data: filteredMatchups,
    rankingStats: filteredRankingStats,
  };

  const hasCounterTips =
    counterTips[championId] && counterTips[championId].adv.length > 0 && counterTips[championId].disadv.length > 0;

  if (filteredMatchups.length === 0) {
    return (
      <div className="content-section champion-profile-error">
        <img src={getNotFoundImg()} alt="No data found" />
        No data found. Please try again or change filters.
      </div>
    );
  }

  return (
    <div className="counters-container">
      <CountersListView
        {...commonProps}
        listType={"best-win-rate"}
        snippetIcon={"https://static.bigbrain.gg/assets/ugg/icons/blue-bg-check.svg"}
      />
      <CountersListView
        {...commonProps}
        listType={"worst-win-rate"}
        snippetIcon={"https://static.bigbrain.gg/assets/ugg/icons/red-skull.svg"}
      />
      <CountersListView
        columnIndex={3}
        {...commonProps}
        listType={"gold-diff"}
        snippetIcon={"https://static.bigbrain.gg/assets/ugg/icons/green-lanes.svg"}
      />
      {hasCounterTips && <CounterTips {...commonProps} />}
    </div>
  );
};

export default CountersContainer;
